<template>
  <v-dialog
    v-model="dialog"
    width="600"
    content-class="rounded-lg"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        elevation="6"
        rounded="lg"
        class="mb-6 mt-6"
        color="primary"
        v-bind="attrs"
        v-on="on"
        >
        <v-card-text
          class="d-flex flex-row white--text align-center"
          style="gap: 24px; position: relative"
          >
          <div>
            <v-icon
              large
              color="white"
              >
              mdi-string-lights
            </v-icon>
          </div>

          <div>
            <div
              class="body-1 font-weight-bold d-flex justify-space-between align-center mb-1"
              >
              <div>
                MIS FERIAS
              </div>

              <div>
                <v-icon
                  color="white"
                  class="mt-n1"
                  size="20"
                  >
                  mdi-bell
                </v-icon>
              </div>
            </div>

            <div
              class="caption"
              >
              ¡Ahora puedes crear y administrar ferias QR!
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>

    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="px-6 pb-6"
        >
        <div
          class="text-center"
          >
          <v-icon
            size="60"
            color="primary"
            >
            mdi-string-lights
          </v-icon>
        </div>

        <div
          class="text-center text-h5 mb-3 text--primary font-weight-medium"
          >
          ¡Ahora tenemos ferias QR!
        </div>

        <div
          class="text-center text--primary"
          >
          Tus ferias de siempre, pero en formato digital. <br/>
          Podrás crear tus propias ferias digitales, invitar tiendas que se unan a ellas y proporcionar un espacio de crecimiento virtual. <br />
          El control es tuyo: configura las características de la feria, tu comisión por venta y quién puede formar parte de ella.
        </div>

        <div
          class="text-center mt-3 font-weight-bold text-h6 primary--text"
          >
          ¿Listo para comenzar?
        </div>

        <div
          style="width: 100px; height: 4px"
          class="primary mx-auto mt-2"
          >
        </div>

        <create
          ></create>

        <div
          class="mt-2"
          >
          <v-list
            three-line
            dense
            >
            <template
              v-for="fair in fairs"
              >
              <v-list-item
                :to="{ name: 'fairs', params: {id: fair.id} }"
                :class="selected == fair.id ? 'primary white--text' : ''"
                >
                <v-list-item-avatar
                  :color="selected == fair.id ? 'white' : 'primary'"
                  >
                  <v-img 
                    v-if="fair.image"
                    :src="url + fair.image.url"></v-img>

                  <v-icon
                    v-else
                    :color="selected == fair.id ? 'primary' : 'white'"
                    >
                    mdi-string-lights
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="fair.name"></v-list-item-title>
                  <v-list-item-subtitle
                    :class="selected == fair.id ? 'primary white--text' : ''"
                    >{{ (new Date(fair.startsAt)).toLocaleDateString() }} - {{ (new Date(fair.endsAt)).toLocaleDateString() }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-list>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Fairs } from '@/graphql/queries/fairs/fairs'

const Create = () => import('@/components/fairs/Create')

export default {
  data: () => ({
    dialog: false,
    fairs: []
  }),

  created () {
    this.fetchFairs()
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    fetchFairs () {
      this.$apollo.query({
        query: Fairs,
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.fairs = res.data.fairs
      })
    }
  },

  components: {
    Create,
  }
}
</script>
